/**
 * 下载提示弹窗
 *
 */
(function ($) {
  $.extend({
    downloadDialog: function (params) {
      var defaults = {
        title: "温馨提示",
        onSubmit: null,
        needScore: true, // 是否需要积分
      };
      var options = $.extend(defaults, params);
      var dialog = {
        tmpl:
          '<div class="download_dialog-wrap">' +
          '  <div class="download_dialog-content">' +
          '    <div class="download_dialog-header">' +
          '      <div class="download_dialog-close-btn" title="关闭"></div>' +
          '      <div class="download_dialog-title"></div>' +
          "    </div>" +
          '    <div class="download_dialog-body">' +
          "      <div class='download_dialog-tips'>请选择您的下载用途（可多选）</div>" +
          '      <ul class="download_dialog-items"></ul>' +
          "      <div class>" +
          '        <span>其他</span><input class="download_dialog-desc-input" maxlength="20" placeholder="可自行填写，不可超过20个字" value="" />' +
          "      </div>" +
          '      <div class="download_dialog-score-tips">' +
          '        <div class="download_dialog-score-tips-desc"></div>' +
          '        <div class="download_dialog-score-tips-select">选择扣除的帐号：<span></span></div>' +
          // '        <div class="download_dialog-score-tips-total">当前账户积分：<span></span></div>' +
          '        <div class="download_dialog-score-tips-pay">本次下载需要支付积分：<span></span></div>' +
          "      </div>" +
          "    </div>" +
          '    <div class="download_dialog-footer">' +
          '      <button class="download_dialog-footer-btn download_dialog-cancel-btn">取消</button>' +
          '      <button class="download_dialog-footer-btn download_dialog-submit-btn">下载</button>' +
          "    </div>" +
          "  </div>" +
          "</div>",
        __render: function () {
          var $ele = $(this.tmpl);
          $ele.find(".download_dialog-title").text(options.title);
          $.ajax({
            type: "get",
            url: "/Newsearch/getPurposeList",
            success: function (res) {
              var items = (res.data || [])
                .map(function (item) {
                  return (
                    "<li class='download_dialog-item'><label><input  data-id='" +
                    item.id +
                    "' type='checkbox' />" +
                    item.name_val +
                    "</label></li>"
                  );
                })
                .join("");
              $ele.find(".download_dialog-items").append(items);
            },
          });
          $(document.body).append($ele);
        },
        /**
         * 获取选定资源ID的分数信息。
         *
         * @param {array} selectedResIds - 选定的资源ID字符串逗号分割
         * @return {void}
         */
        getScoreInfo(selectedResIds) {
          $.ajax({
            type: "post",
            url: "/score/getScoreInfo",
            data: {
              resIds: selectedResIds,
            },
            success: function (res) {
              if (res.code) {
                layer.msg(res.msg);
                return;
              }

              $(".download_dialog-score-tips-desc").text(
                res.data.resDownScoreInfo
              );
              $(".download_dialog-score-tips-total")
                .children("span")
                .text(res.data.scoreTotal);
              $(".download_dialog-score-tips-pay")
                .children("span")
                .text(res.data.expendScore);
              $(".download_dialog-submit-btn").text("支付并下载").attr(
                "isdown",
                Number(res.data.isDown) // 是否可以下载 1 可以下载；2 不能下载
              );
              $(".download_dialog-submit-btn").text("支付并下载").attr(
                "isdown2",
                Number(res.data.contributionTotal)>=Number(res.data.expendScore)?1:2 // 是否可以下载 1 可以下载；2 不能下载
              );
              let selectHtml = `<select class="download_expend_type" >`;
              if (
                res.data.scoreTotal &&
                Number(res.data.scoreTotal) > Number(res.data.expendScore) &&
                (!res.data.contributionTotal ||
                  Number(res.data.contributionTotal) <
                    Number(res.data.expendScore))
              ) {
                selectHtml += `<option value="1">当前科普号贡献值${res.data.contributionTotal}</option>
                <option value="2" selected>当前科普员积分${res.data.scoreTotal}</option></select>`;
              } else {
                selectHtml += `<option value="1" selected>当前科普号贡献值${res.data.contributionTotal}</option>
                <option value="2" >当前科普员积分${res.data.scoreTotal}</option></select>`;
              }
              $(".download_dialog-score-tips-select")
                .children("span")
                .html(selectHtml);
            },
          });
        },
        show: function (selectedResIds) {
          if (options.needScore) {
            if (!selectedResIds) {
              layer.msg("请选择下载资源");
              return;
            }
            dialog.getScoreInfo(selectedResIds);
          } else {
            $(".download_dialog-score-tips").remove();
            $(".download_dialog-submit-btn")
              .text("下载");
          }
          $(".download_dialog-wrap")
            .show()
            .find(".download_dialog-item input:checked")
            .prop("checked", false);
          $(".download_dialog-wrap")
            .find(".download_dialog-desc-input")
            .val("");
          return this;
        },
        close: function () {
          $(".download_dialog-wrap").hide();
          return this;
        },
        onSubmit: function (func) {
          options.onSubmit = func;
        },
        __init: function () {
          var self = this;
          self.__render();
          $(document.body)
            .on("click", ".download_dialog-close-btn", function () {
              dialog.close();
            })
            .on("click", ".download_dialog-cancel-btn", function () {
              dialog.close();
            })
            .on("click", ".download_dialog-submit-btn", function (event) {
              var ids = $(".download_dialog-body")
                .find(".download_dialog-item input:checked")
                .map(function (index, element) {
                  return $(this).data("id");
                })
                .get()
                .join(",");
              var desc = $(".download_dialog-body")
                .find(".download_dialog-desc-input")
                .val()
                .trim();
              var type = $('.download_expend_type').val();
              if ($('.download_expend_type').val()==2 && options.needScore && $(this).attr("isdown") == 2) {
                layer.msg("当前积分不足，无法下载");
                return;
              }
              if (type==1 && options.needScore && $(this).attr("isdown2") == 2) {
                layer.msg("当前贡献值不足，无法下载");
                return;
              }
              options.onSubmit && options.onSubmit(ids, desc, type);
            });
        },
      };
      dialog.__init();

      return dialog;
    },
  });
})(window.jQuery);
